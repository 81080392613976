export class KisiModel {
    guid: string;
    kullaniciId: number;
    kullaniciAdi: string;
    alinanSiparisOnaylayabilir: boolean;
    satinAlmaTalebiOnaylayabilir: boolean;
    alinanTeklifOnaylayabilir: boolean
    alinanTeklifMiktar: number;
    verilenTeklifOnaylayabilir: boolean;
    verilenTeklifMiktar: number;
    satinAlmaTalebiMiktar: number;
    alinanSiparisMiktar: number;
    satisFaturalariGorebilir: boolean;
    alisFaturalariGorebilir: boolean;
    verilenTeklifGorebilir: boolean;
    alinanTeklifGorebilir: boolean;

    projeKodu: string;
    srmMrkKodu: string;
    firmaNo: string;
    subeNo: string;
    kasaKodu: string;
    evrakSeri: string;
    mikroKullaniciNo: string;
    depoNo: string;
    lastUpdate: Date;
    lastUpUserId: number;


    alinanTeklifOnay1: boolean;
    alinanTeklifOnay2: boolean;
    alinanTeklifOnay3: boolean;

    satinAlmaTalebiOnay1: boolean;
    satinAlmaTalebiOnay2: boolean;
    satinAlmaTalebiOnay3: boolean;

    verilenTeklifOnay1: boolean;
    verilenTeklifOnay2: boolean;
    verilenTeklifOnay3: boolean;



    public Map(x) {
        this.guid = x.snr_Guid;
        this.kullaniciId = x.kullaniciId;
        this.satinAlmaTalebiMiktar = x.satinAlmaTalebiMiktar;
        this.alinanSiparisMiktar = x.alinanSiparisMiktar;
        this.alinanTeklifOnaylayabilir = x.alinanTeklifOnaylayabilir;
        this.verilenTeklifOnaylayabilir = x.verilenTeklifOnaylayabilir;
        this.satinAlmaTalebiOnaylayabilir = x.satinAlmaTalebiOnaylayabilir;
        this.alinanSiparisOnaylayabilir = x.alinanSiparisOnaylayabilir;
        this.alinanTeklifMiktar = x.alinanTeklifMiktar;
        this.verilenTeklifMiktar = x.verilenTeklifMiktar;
        this.satisFaturalariGorebilir = x.satisFaturalariGorebilir;
        this.alisFaturalariGorebilir = x.alisFaturalariGorebilir;
        this.verilenTeklifGorebilir = x.verilenTeklifGorebilir;
        this.alinanTeklifGorebilir = x.alinanTeklifGorebilir;
        this.kullaniciAdi = x.kullaniciAdi || "yok";
        this.lastUpdate = x.lastUpdate;
        this.lastUpUserId = x.lastUpUserId;
        this.projeKodu = x.projeKodu;
        this.srmMrkKodu = x.srmMrkKodu;
        this.firmaNo = x.firmaNo;
        this.subeNo = x.subeNo;
        this.kasaKodu = x.kasaKodu;
        this.evrakSeri = x.evrakSeri;
        this.mikroKullaniciNo = x.mikroKullaniciNo;
        this.depoNo = x.depoNo;
        this.satinAlmaTalebiOnay1 = x.satinAlmaTalebiOnay1;
        this.satinAlmaTalebiOnay2 = x.satinAlmaTalebiOnay2;
        this.satinAlmaTalebiOnay3 = x.satinAlmaTalebiOnay3;
        this.alinanTeklifOnay1 = x.alinanTeklifOnay1;
        this.alinanTeklifOnay2 = x.alinanTeklifOnay2;
        this.alinanTeklifOnay3 = x.alinanTeklifOnay3;
        this.verilenTeklifOnay1 = x.verilenTeklifOnay1;
        this.verilenTeklifOnay2 = x.verilenTeklifOnay2;
        this.verilenTeklifOnay3 = x.verilenTeklifOnay3;

    }
}