import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AuthService } from "app/auth.service";
import { Router } from "@angular/router";
import { MikroService } from "app/mikro.service";
import { KisiModel } from "app/components/admin_panel/kisi_model.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"]
})
export class LoginComponent implements OnInit {
  signinForm: FormGroup;
  kullaniciBilgisi: KisiModel = new KisiModel();

  constructor(private fb: FormBuilder, private auth: AuthService, private router: Router, private mikro: MikroService) { }

  ngOnInit() {
    this.signinForm = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required]
    });
  }

  async signin() {
    try {
      this.auth.signin(this.signinForm.value).subscribe(async res => {

        this.saveHeaders(res);
        await this.kullanicilariKontrolEt();
        await this.kullaniciBilgileriniAl();
        await this.apiVersiyonuKaydet();
        await this.router.navigateByUrl("/stok-bilgi");

      });
    } catch (error) {
      console.log("Login error: ", error);
    }
  }
  async apiVersiyonuKaydet() {
    const apiVersion = await this.mikro.apiVersiyonuAl();
    this.auth.setApiVersion(apiVersion);

  }

  saveHeaders(response) {

    console.log("attributes: ", response);

    // Attribute'ları db'ye kaydet.

    var yetkiSeviyesi = response.attributes.find(val => val.attributeKey === "YetkiSeviyesi");
    var kullaniciMikroId = response.attributes.find(val => val.attributeKey === "KullaniciNo");

    console.log("kullaniciMikroId : ", kullaniciMikroId);
    if (kullaniciMikroId !== undefined) {
      localStorage.setItem("kullaniciMikroNo", kullaniciMikroId.attributeValue);
    }

    if (yetkiSeviyesi == undefined || yetkiSeviyesi == null) {
      localStorage.setItem("yetkiSeviyesi", "0");

    }
    else {
      localStorage.setItem("yetkiSeviyesi", yetkiSeviyesi.attributeValue);

    }

    console.log("yetki seviyesi : ", yetkiSeviyesi);
    localStorage.setItem("kullaniciId", response.id);
  }

  async kullanicilariKontrolEt() {

    await this.mikro.KullanicilariKontrolEt();
  }

  async kullaniciBilgileriniAl() {

    var id = parseInt(localStorage.getItem("kullaniciId"));


    const kullaniciBilgileri: KisiModel = await this.mikro.GetSingleKullaniciBilgileri(id);
    localStorage.setItem("kullaniciBilgileri", JSON.stringify(kullaniciBilgileri));
    this.auth.tumVerileriAl();
  }
}
