import { Component, OnInit } from "@angular/core";
import { AuthService } from "app/auth.service";
import { Router } from "@angular/router";
import { KisiModel } from "../admin_panel/kisi_model.component";

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export var ROUTES: RouteInfo[] = [

  {
    path: "/stok-bilgi",
    title: "Ürün Bilgileri",
    icon: "shopping_cart",
    class: "",
  },
  {
    path: "/alinan-siparis-liste",
    title: "Alınan Siparişler",
    icon: "archive",
    class: "",
  },
  {
    path: "/verilen-siparis-liste",
    title: "Verilen Siparişler",
    icon: "unarchive",
    class: "",
  },
  {
    path: "/depo-sevk-liste",
    title: "Depolar Arası Sevkler",
    icon: "import_export",
    class: "",
  },
  {
    path: "/depolar-arasi-siparis-list",
    title: "Depolar Arası Sipariş Fişi",
    icon: "receipt",
    class: ""
  },
  {
    path: "/devir-giris-liste",
    title: "Stok Giriş Fişleri",
    icon: "arrow_downward",
    class: "",
  },
  {
    path: "/devir-cikis-liste",
    title: "Stok Çıkış Fişleri",
    icon: "arrow_upward",
    class: "",
  },
  {
    path: "/sarf-cikisi-fisi-liste",
    title: "Sarf Çıkışı Depo Fişleri",
    icon: "trending_neutral",
    class: "",
  },
  {
    path: "/tahsilat-liste",
    title: "Tahsilat Makbuzları",
    icon: "payment",
    class: "",
  },
  {
    path: "/tediye-liste",
    title: "Tediye Makbuzları",
    icon: "payment",
    class: "",
  },

  {
    path: "/teklif-tanitim-karti",
    title: "Teklif Tanıtım Kartı",
    icon: "summarize",
    class: "",
  },

  {
    path: "/satinalma-liste",
    title: "Satınalma Talepleri",
    icon: "summarize",
    class: "",
  },
  {
    path: "/evrak-tasarim",
    title: "Evrak Tasarım",
    icon: "extension",
    class: "",
  },


];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  menuItems: any[];

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
    this.checkParameters();

    this.menuItems = ROUTES.filter((menuItem) => menuItem);
  }
  isMobileMenu() {
    if ($(window).width() > 1200) {
      return false;
    }
    return true;
  }

  oturumuKapat() {
    this.auth.currentUser = null;
    localStorage.clear();
    this.paneliTemizle();
    this.router.navigateByUrl("/login");
  }


  checkParameters() {

    var girisYapanKullaniciBilgileiri: KisiModel = JSON.parse(localStorage.getItem("kullaniciBilgileri")) as KisiModel;


    console.log(girisYapanKullaniciBilgileiri);
    //parseInt(localStorage.getItem("satinalmaTalepOnaylayabilir"));
    var adminMi = parseInt(localStorage.getItem("yetkiSeviyesi"));

    if (adminMi === 1) {
      var adminPanel = {
        path: "/admin-paneli",
        title: "Admin Paneli",
        icon: "shopping_cart",
        class: "",
      };

      ROUTES.unshift(adminPanel);
    }


    if (girisYapanKullaniciBilgileiri.satinAlmaTalebiOnaylayabilir === true) {
      var onayJson = {
        path: "/satinalma-onay",
        title: "Satın Alma Talebi Onaylama",
        icon: "check",
        class: "",
      };

      ROUTES.push(onayJson);
    }


    if (girisYapanKullaniciBilgileiri.alinanTeklifOnaylayabilir === true) {
      var teklifOnayJson = {
        path: "/teklif-onay",
        title: "Alınan Teklif Onaylama",
        icon: "check",
        class: ""

      };

      ROUTES.push(teklifOnayJson);

    }

    if (girisYapanKullaniciBilgileiri.verilenTeklifOnaylayabilir === true) {
      var verilenTeklifOnayJson = {
        path: '/verilen-teklif-onay-list',
        title: 'Verilen Teklif Onaylama',
        icon: 'check',
        class: '',
      }

      ROUTES.push(verilenTeklifOnayJson);
    }

    if (girisYapanKullaniciBilgileiri.satisFaturalariGorebilir === true) {
      var satisFaturasi = {
        path: "/satis-fatura-liste",
        title: "Satış Faturaları",
        icon: "shopping_cart",
        class: "",
      };
      ROUTES.splice(2, 0, satisFaturasi); // 1. sıraya eklemek için

      // Diziyi tekrar atanması gerekir.
      ROUTES = ROUTES.slice();
    }

    if (girisYapanKullaniciBilgileiri.alisFaturalariGorebilir === true) {
      var alisFature = {
        path: "/alis-fatura-liste",
        title: "Alış Faturaları",
        icon: "receipt",
        class: "",
      };
      ROUTES.splice(3, 0, alisFature); // 1. sıraya eklemek için

      // Diziyi tekrar atanması gerekir.
      ROUTES = ROUTES.slice();
    }

    if (girisYapanKullaniciBilgileiri.verilenTeklifGorebilir === true) {
      var teklif = {
        path: "/teklif-liste",
        title: "Verilen Teklifler",
        icon: "summarize",
        class: "",
      };

      ROUTES.splice(10, 0, teklif); // 1. sıraya eklemek için

      // Diziyi tekrar atanması gerekir.
      ROUTES = ROUTES.slice();
    }

    if (girisYapanKullaniciBilgileiri.alinanTeklifGorebilir === true) {
      var alinanTeklif = {
        path: "/alinan-teklif-liste",
        title: "Alınan Teklifler",
        icon: "summarize",
        class: "",
      };

      ROUTES.splice(11, 0, alinanTeklif); // 1. sıraya eklemek için
    }


  }

  paneliTemizle() {
    ROUTES = ROUTES.filter(item => item.path !== "/admin-paneli");
    ROUTES = ROUTES.filter(item => item.path !== "/satinalma-onay");
    ROUTES = ROUTES.filter(item => item.path !== "/teklif-onay");
    ROUTES = ROUTES.filter(item => item.path !== "/verilen-teklif-onay-list");
    ROUTES = ROUTES.filter(item => item.path !== "/satis-fatura-liste");
    ROUTES = ROUTES.filter(item => item.path !== "/alis-fatura-liste");
    ROUTES = ROUTES.filter(item => item.path !== "/alinan-teklif-liste");
    ROUTES = ROUTES.filter(item => item.path !== "/teklif-liste");

  }
}
