import { Injectable, isDevMode } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { reject, resolve } from "../../__node_modules/promise";
import { C, I } from "../../__node_modules/@angular/cdk/keycodes";
import { KisiModel } from "./components/admin_panel/kisi_model.component";
import { VerilenTeklifListDto } from "./components/admin_panel/models/verilen_teklif_list_dto.component";
import { ConsoleLogger } from "../../__node_modules/@angular/compiler-cli/ngcc";
import { ConstructorDepErrorKind } from "../../__node_modules/@angular/compiler-cli/src/ngtsc/annotations/src/util";
import { EvrakOnaySayisiModel } from "./components/admin_panel/models/evrak_onay_sayisi_model.component";
import { EvrakOnayListModel } from "./models/evrak_onay_list_model";
import { StokTanitimKartiModel } from "./models/stok/stok_tanitim_karti_model";
import { KeyValueModel } from "./models/key_value_model";
import { SarfCikisiDepoFisiModel } from "./sarf_cikisi_depo_fisi/model/sarf_cikisi_depo_fisi_model";
import { SatinAlmaTalebiOnay } from "./models/onay/satinalma_talebi_onay/satinalma_talebi_onay";

@Injectable({ providedIn: "root" })
export class MikroService {




  // alinanTeklif.cariTipi; //byte
  // alinanTeklif.dovizCinsi; // byte
  // alinanTeklif.dovizKuru; //decimal
  // alinanTeklif.teslimTuru;
  // alinanTeklif.teslimTarihi;
  // alinanTeklif.belgeTarihi;
  // alinanTeklif.sorumluKodu;
  // alinanTeklif.odemePlani; // byte
  // alinanTeklif.projeKodu; // string
  // alinanTeklif.srmmrk; // string
  // alinanTeklif.items = [
  // ];

  constructor(private http: HttpClient, private auth: AuthService) {
    // this.auth.ApiUrl = "http://localhost:7810/api/";
  }
  public getCariList(cariunvan: string) {
    return this.http.get(
      this.auth.ApiUrl + "lookup/getcarihesaplar?unvan=" + cariunvan
    );
  }
  public getAdayCariList(cariunvan: string) {
    return this.http.get(
      this.auth.ApiUrl + "lookup/getadaycarihesaplar?unvan=" + cariunvan
    );
  }
  public getCariBakiye(carikod: string) {
    return this.http.get(
      this.auth.ApiUrl + "lookup/GetCariHesapBakiye?carikodu=" + carikod
    );
  }
  public getKasaList() {
    return this.http.get(this.auth.ApiUrl + "lookup/getkasalar");
  }
  public getMasrafHesaplari() {
    return this.http.get(this.auth.ApiUrl + "lookup/GetMasrafHesaplari");
  }
  public async getMasrafHesaplariAsKeyValueModel(filtre: string) {
    return await this.http.get<KeyValueModel[]>(this.auth.ApiUrl + "lookup/getMasrafHesaplariAsKeyValueModel?filtre=" + filtre).toPromise();
  }
  public getRenkList(renkKodu) {
    return this.http.get(
      this.auth.ApiUrl + "lookup/GetRenkKrilim?renkKodu=" + renkKodu
    );
  }
  public getBedenList(bedenKodu) {
    return this.http.get(
      this.auth.ApiUrl + "lookup/GetBedenKrilim?bedenKodu=" + bedenKodu
    );
  }
  public getBankaList() {
    return this.http.get(this.auth.ApiUrl + "lookup/getbankalar");
  }
  public GetProjeler(filtre) {
    return this.http.get(
      this.auth.ApiUrl + "lookup/getProjeler?filtre=" + filtre
    );
  }
  public GetSrmMerkezleri(filtre) {
    return this.http.get(
      this.auth.ApiUrl + "lookup/GetSrmMerkezleri?filtre=" + filtre
    );
  }
  public GetCariPersonelleri(filtre) {
    return this.http.get(
      this.auth.ApiUrl + "lookup/GetCariPersonelleri?filtre=" + filtre
    );
  }
  public GetTeslimTurleri(filtre) {
    return this.http.get(
      this.auth.ApiUrl + "lookup/GetTeslimTurleri?filtre=" + filtre
    );
  }
  public GetOdemePlanlari(filtre) {
    return this.http.get(
      this.auth.ApiUrl + "lookup/GetOdemePlanlari?filtre=" + filtre
    );
  }
  public getDepoList() {



    return this.http.get(this.auth.ApiUrl + "lookup/getdepolar");
  }
  public GetTeklifKodlari() {
    return this.http.get(this.auth.ApiUrl + "lookup/GetTeklifler");
  }

  public maxTalepSira(seri) {
    return this.http.get(
      this.auth.ApiUrl + "talep/GetMaxEvrakNo?evrakSeri=" + seri
    );
  }

  public maxVerilenTeklifSira(seri) {
    return this.http.get(this.auth.ApiUrl + "teklif/GetVerilenTeklifMaxSiraNo?teklifKodu=" + seri);
  }

  public maxEvrakSira(seri, tip) {
    tip = tip.trim();

    if (tip == "sevk")
      return this.http.get(
        this.auth.ApiUrl + "lookup/MaxDepoSevkEvrakNo?evrakSeri=" + seri
      );
    else if (tip == "devirgiris")
      return this.http.get(
        this.auth.ApiUrl + "lookup/MaxDevirGirisEvrakNo?evrakSeri=" + seri
      );
    else if (tip == "devircikis")
      return this.http.get(
        this.auth.ApiUrl + "lookup/MaxDevirCikisEvrakNo?evrakSeri=" + seri
      );
    else if (tip == "alinansiparis")
      return this.http.get(
        this.auth.ApiUrl + "lookup/MaxAlinanSiparisEvrakNo?evrakSeri=" + seri
      );
    else if (tip == "verilensiparis")
      return this.http.get(
        this.auth.ApiUrl + "lookup/MaxVerilenSiparisEvrakNo?evrakSeri=" + seri
      );
    else if (tip == "tahsilat")
      return this.http.get(
        this.auth.ApiUrl + "lookup/MaxtTahsilatEvrakNo?evrakSeri=" + seri
      );
    else if (tip == "tediye")
      return this.http.get(
        this.auth.ApiUrl + "lookup/MaxtTediyeEvrakNo?evrakSeri=" + seri
      );
    else if (tip == "alisfaturasi")
      return this.http.get(
        this.auth.ApiUrl + "lookup/MaxAlisFaturaEvrakNo?evrakSeri=" + seri
      );
    else if (tip == "depolarArasiSiparisFisi")
      return this.http.get(this.auth.ApiUrl + "lookup/MaxDepolarArasiSiparisNo?evrakSeri=" + seri);
    else
      return this.http.get(
        this.auth.ApiUrl + "lookup/MaxSatisFaturaEvrakNo?evrakSeri=" + seri
      );
  }
  public GetStokByBarkod(barkod) {
    return this.http.get(
      this.auth.ApiUrl + "lookup/GetStokByBarkod?barkodu=" + barkod
    );
  }
  public GetStoklarByAdi(stokadi, cinsi) {
    return this.http.get(
      this.auth.ApiUrl +
      "lookup/GetStoklarByAdi?stokAdi=" +
      stokadi +
      "&cinsi=" +
      cinsi
    );
  }
  public GetRenkBedenDepoDurum(stokkodu) {
    return this.http.get(
      this.auth.ApiUrl + "lookup/GetRenkBedenDepoDurum?stokKodu=" + stokkodu
    );
  }
  public GetFaturaList(basTar, bitTar, depo) {
    return this.http.get(
      this.auth.ApiUrl +
      "fatura/GetFaturaList?basTar=" +
      basTar +
      "&bitTar=" +
      bitTar +
      "&tip=satisfaturasi&depo=" +
      depo
    );
  }
  public GetAlisFaturaList(basTar, bitTar, depo) {
    return this.http.get(
      this.auth.ApiUrl +
      "fatura/GetFaturaList?basTar=" +
      basTar +
      "&bitTar=" +
      bitTar +
      "&tip=alisfaturasi&depo=" +
      depo
    );
  }
  public GetSiparisList(basTar, bitTar, tip, depo) {
    return this.http.get(
      this.auth.ApiUrl +
      "siparis/GetSiparisList?basTar=" +
      basTar +
      "&bitTar=" +
      bitTar +
      "&tip=" +
      tip +
      "&depo=" +
      depo
    );
  }
  public GetSevkList(basTar, bitTar, evraktip, depo) {
    return this.http.get(
      this.auth.ApiUrl +
      "deposevk/GetSevkList?basTar=" +
      basTar +
      "&bitTar=" +
      bitTar +
      "&tip=" +
      evraktip +
      "&depo=" +
      depo
    );
  }
  public GetTahsilatList(basTar, bitTar, evraktip) {
    return this.http.get(
      this.auth.ApiUrl +
      "tahsilat/GetTahsilatList?basTar=" +
      basTar +
      "&bitTar=" +
      bitTar +
      "&tip=" +
      evraktip
    );
  }
  public GetVerilenTeklifList(basTar, bitTar) {
    return this.http.get(
      this.auth.ApiUrl +
      "teklif/GetTeklifList?basTar=" +
      basTar +
      "&bitTar=" +
      bitTar
    );
  }
  public GetAlinanTeklifList(basTar, bitTar) {
    return this.http.get(
      this.auth.ApiUrl +
      "teklif/GetAlinanTeklifFis?from=" +
      basTar +
      "&to=" +
      bitTar
    );
  }

  public SaveAlinanTekliFis(fis) {
    this.updateModel(fis);

    console.log("tkl 1: ", fis["tklGuid"]);
    fis["tklGuid"] = "00000000-0000-0000-0000-000000000000";
    console.log("tkl 2: ", fis["tklGuid"]);
    // console.log(fis);
    // let alinanTeklif: any;
    // // alinanTeklif.tklGuid = fis.tklGuid;
    // alinanTeklif.teklifKodu = fis.teklifKodu;
    // alinanTeklif.siraNo = fis.siraNo;
    // //alinanTeklif.belgeNo = fis.;
    // alinanTeklif.cariKodu = fis.cariKodu;
    // alinanTeklif.cariAdi = fis.cariUnvani;
    // alinanTeklif.cariTipi; //byte
    // alinanTeklif.dovizCinsi; // byte
    // alinanTeklif.dovizKuru; //decimal
    // alinanTeklif.teslimTuru;
    // alinanTeklif.teslimTarihi;
    // alinanTeklif.belgeTarihi;
    // alinanTeklif.sorumluKodu;
    // alinanTeklif.odemePlani; // byte
    // alinanTeklif.projeKodu; // string
    // alinanTeklif.srmmrk; // string
    // alinanTeklif.items = [

    // ];

    console.log("Fiş");
    console.log(fis);
    return this.http.post(this.auth.ApiUrl + "Teklif/SaveAlinanTeklifFis", fis);
  }

  public GetSatinalmaTalepList(basTar: string, bitTar: string, depo) {


    return this.http.get(
      this.auth.ApiUrl +
      "talep/GetTalepList?basTar=" +
      basTar +
      "&bitTar=" +
      bitTar +
      "&depo=" +
      depo
    );
  }
  public async GetTalepOnayList(basTar, bitTar, evrakNo: number, oncekileriGoster: boolean): Promise<SatinAlmaTalebiOnay[]> {
    var kullaniciId: string = (JSON.parse(localStorage.getItem("kullaniciBilgileri")) as KisiModel).mikroKullaniciNo;

    const response = await this.http.get<SatinAlmaTalebiOnay[]>(
      this.auth.ApiUrl +
      "talep/GetTalepOnayList?basTar=" +
      basTar +
      "&bitTar=" +
      bitTar +
      "&kullaniciId=" +
      kullaniciId +
      "&evrakTuru=" +
      evrakNo +
      "&onayVerilenleriGoster=" +
      oncekileriGoster
    ).toPromise();

    // API'den gelen veriyi TalepModel sınıfına cast et ve dizi olarak döndür
    // var list = (response as any[]).map(item => new EvrakOnayListModel(item));
    // console.log("GetTalepOnayList", list);

    console.log("Gelen talpelerrr : ", response);
    return response;
  }
  public GetDovizAd(carikod) {
    return this.http.get(
      this.auth.ApiUrl + "lookup/GetDovizAd?carikod=" + carikod
    );
  }

  public GetDovizAdlari() {
    return this.http.get(this.auth.ApiUrl + "lookup/GetDovizAdlari");
  }

  public GetDovizKur(dovizcinsi) {

    return this.http.get(
      this.auth.ApiUrl + "lookup/GetDovizKur?dovizcinsi=" + dovizcinsi
    );
  }

  public GetVergi() {
    return this.http.get(this.auth.ApiUrl + "lookup/GetVergiler");
  }

  updateModel(model) {
    model.depoNo = this.auth.DepoNo;

    if (model.srmMerkezi == null) model.srmMerkezi = this.auth.SrmMerkezi;
    if (model.projeKodu == null) model.projeKodu = this.auth.ProjeKodu;
    if (model.kullaniciNo == null) model.kullaniciNo = this.auth.mikroKullaniciNo;
    if (model.kasaKodu == null) model.kasaKodu = this.auth.kasaKodu;

    model.firmaNo = this.auth.FirmaNo;
    model.subeNo = this.auth.SubeNo;

    model.items.forEach((element) => {
      if (element.teklifGuid === null || element.teklifGuid === undefined) {
        element.teklifGuid = "00000000-0000-0000-0000-000000000000";
      }

      if (element.karsidkur === null || element.karsidkur === undefined) {
        element.karsidkur = "0";
      }

      if (element.karsidcinsi === null || element.karsidcinsi === undefined) {
        element.karsidcinsi = "1";
      }
    });

    console.log(model);
  }

  updateTalepTeklifModel(model, evrakTipi) {
    if (evrakTipi == 0) model.depoNo = this.auth.DepoNo;

    if (model.srmMrkKodu == null) model.srmMrkKodu = this.auth.SrmMerkezi;
    if (model.projeKodu == null) model.projeKodu = this.auth.ProjeKodu;
    if (model.kullaniciNo == null) model.kullaniciNo = this.auth.mikroKullaniciNo;

    model.depoNo = model.cikisdepono;
    model.firmaNo = this.auth.FirmaNo;
    model.subeNo = this.auth.SubeNo;

    console.log(model);
  }
  public SaveTalep(talep) {
    this.updateTalepTeklifModel(talep, 0);
    return this.http.post(this.auth.ApiUrl + "talep/SaveTalep", talep);
  }



  public SaveTeklif(teklif) {
    this.updateTalepTeklifModel(teklif, 1);
    console.log("teklif : ", teklif);

    return this.http.post(this.auth.ApiUrl + "teklif/SaveTeklif", teklif);
  }
  public SaveFatura(fatura) {
    this.updateModel(fatura);
    return this.http.post(this.auth.ApiUrl + "fatura/SaveFatura", fatura);
  }
  public SaveSiparis(siparis) {
    this.updateModel(siparis);
    return this.http.post(this.auth.ApiUrl + "siparis/SaveSiparis", siparis);
  }
  public SaveSevk(sevk) {
    this.updateModel(sevk);
    console.log("sevk model : ", sevk);
    return this.http.post(this.auth.ApiUrl + "deposevk/SaveDepoSevk", sevk);
  }
  public SaveTahsilat(tahsilat) {
    this.updateModel(tahsilat);

    if (tahsilat.cha_Guid === null || tahsilat.cha_Guid === undefined) {
      tahsilat.cha_Guid = "00000000-0000-0000-0000-000000000000";
    }
    tahsilat.items.forEach((element) => {
      if (element.sth_Guid === null || element.sth_Guid === undefined) {
        element.sth_Guid = "00000000-0000-0000-0000-000000000000";
      }

      if (element.teklifGuid === null || element.teklifGuid === undefined) {
        element.teklifGuid = "00000000-0000-0000-0000-000000000000";
      }
    });
    console.log("saveTahsilat : ", tahsilat);
    return this.http.post(this.auth.ApiUrl + "tahsilat/SaveTahsilat", tahsilat);
  }
  public GetFatura(kayitno) {
    return this.http.get(
      this.auth.ApiUrl + "fatura/GetFatura?kayitno=" + kayitno
    );
  }
  public GetSiparis(kayitno) {
    return this.http.get(
      this.auth.ApiUrl + "siparis/GetSiparis?kayitno=" + kayitno
    );
  }
  public GetTahsilat(kayitno) {
    return this.http.get(
      this.auth.ApiUrl + "tahsilat/GetTahsilat?kayitno=" + kayitno
    );
  }
  public GetTalep(kayitno) {
    return this.http.get(
      this.auth.ApiUrl + "talep/GetTalep?kayitNo=" + kayitno
    );
  }
  public GetTeklif(kayitno) {
    return this.http.get(
      this.auth.ApiUrl + "teklif/GetTeklif?kayitNo=" + kayitno
    );
  }
  public GetAlinanTeklif(guid) {
    return this.http.get(
      this.auth.ApiUrl + "teklif/GetSingleAlinanTeklifFis?guid=" + guid
    );
  }
  public GetSevk(kayitno) {
    return this.http.get(
      this.auth.ApiUrl + "deposevk/GetDepoSevk?kayitno=" + kayitno
    );
  }
  async GetMaxSiraNo(teklifKod: string) {
    let maxSiraNo: number;
    maxSiraNo = await this.http
      .get<number>(
        this.auth.ApiUrl + "teklif/GetMaxSiraNo?teklifKodu=" + teklifKod
      )
      .toPromise();
    // subscribe((response: number) => {

    //   console.log("response");
    //   console.log(response);
    //   maxSiraNo = response;
    // });

    console.log("maxSirano in api");
    console.log(maxSiraNo);

    return maxSiraNo;
  }

  GetAllTeklifTanitimKarti(from, to) {
    return this.http.get(
      this.auth.ApiUrl +
      "teklif/GetAllTeklifTanitimKarti?from=" +
      from +
      "&to=" +
      to
    );
  }

  GetTeklifTanitimKart(guid: string) {
    return this.http.get(
      this.auth.ApiUrl + "teklif/GetSingleTanitimKarti?teklif_kodu=" + guid
    );
  }

  SaveTeklifTanitimKart(value: any) {
    return this.http.post(
      this.auth.ApiUrl + "teklif/SaveTeklifTanitimKart",
      value
    );
  }

  TalepOnay(evrakSeri, evrakSira) {

    var kullaniciBilgileri: KisiModel = JSON.parse(localStorage.getItem("kullaniciBilgileri"));
    console.log("Mikro kullaniciNo", kullaniciBilgileri.mikroKullaniciNo);
    return new Promise((resolve, reject) => {
      const talepOnayModel = {
        EvrakNo: evrakSeri + "-" + evrakSira,
        OnaylayanMikroKullaniciAdi: kullaniciBilgileri.mikroKullaniciNo,
      };

      var response = this.http
        .post(this.auth.ApiUrl + "talep/TalepOnay", talepOnayModel)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  // Kullanıcının talep onaylama limiti, evrağın tutarından fazla ise,
  // Evrak onay sayısı ne olursa olsun hepsini onayla.
  public async TalebinTamaminiOnayla(evrakSeri, evrakSira): Promise<any> {

    var kullaniciBilgileri: KisiModel = this.GetKayitliKullanici();

    const model = {
      EvrakNo: evrakSeri + "-" + evrakSira,
      OnaylayanKullaniciId: kullaniciBilgileri.mikroKullaniciNo,
    };
    var response = await this.http.post(this.auth.ApiUrl + "talep/TalepTamOnay", model).toPromise();

    return response;
  }


  TalepKapat(evrakSeri, evrakSira) {

    var kullaniciBilgileri: KisiModel = JSON.parse(localStorage.getItem("kullaniciBilgileri"));
    console.log("Mikro KullaniciNo : ", kullaniciBilgileri.mikroKullaniciNo);
    return new Promise((resolve, reject) => {
      const dto = {
        evrakNo: evrakSeri + "-" + evrakSira,
        onaylayanMikroKullaniciAdi: kullaniciBilgileri.mikroKullaniciNo,
      };

      this.http.post(this.auth.ApiUrl + "talep/TalepKapat", dto).subscribe(
        (response) => {
          resolve(response); // İşlem başarılı oldu
        },
        (error) => {
          reject(error); // İşlem hatayla sonuçlandı
        }
      );
    });
  }

  GetTalep2(seriNo: string, siraNo: string) {
    this.http
      .get(
        this.auth.ApiUrl +
        "talep/GetTalepUsingSeriAndSira" +
        "?evrakSeri=" +
        seriNo +
        "&evrakSiraNo=" +
        siraNo
      )
      .subscribe(
        (response) => {
          return response;
        },
        (error) => { }
      );
  }
  GetAdminPanelData() {
    return new Promise((resolve, reject) => {
      this.http.get(this.auth.ApiUrl + "adminPanel/GetKullanicilar").subscribe(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });

    return;
  }

  async SaveKullanici(data: any) {
    return await this.http
      .post(this.auth.ApiUrl + "adminPanel/SaveKullanici", data).toPromise();

  }

  async GetEvrakOnaySayilari() {
    return await this.http.get<EvrakOnaySayisiModel[]>(this.auth.ApiUrl + "adminPanel/GetEvrakOnaySayilari").toPromise();
  }

  async SaveTeklifOnaySayilari(teklifOnaySayilari) {
    try {
      var kullaniciId: number = parseInt(localStorage.getItem("kullaniciId"));
      var kaydedildiMi = await this.http.post(this.auth.ApiUrl + "adminPanel/SaveEvrakOnaySayilari?userId=" + kullaniciId, teklifOnaySayilari).toPromise();
      console.log("Kaydedildi mi", kaydedildiMi);
      return kaydedildiMi;

    } catch (error) {
      console.log("Onay sayilarını kaydederken hata:", error);
      return false;
    }
  }

  async KullanicilariKontrolEt() {
    try {
      var kullanicilar;

      kullanicilar = await this.http
        .get("https://auth.finuans.com/api/user/GetUsers")
        .toPromise();

      await this.http
        .post(this.auth.ApiUrl + "adminPanel/SaveBasicKullanicilar", kullanicilar)
        .toPromise();
    } catch
    (exception) {
      console.log("Basic kullanicilarda hata");
    }

  }

  async GetSingleKullaniciBilgileri(kullaniciId: number): Promise<KisiModel> {
    try {
      const response = await this.http
        .get(
          this.auth.ApiUrl +
          "adminPanel/GetSingleKullanici?kullaniciId=" +
          kullaniciId
        )
        .toPromise();
      return response as KisiModel;
    } catch (error) {
      console.log("Kullanıcı bilgilerini alırken hata meydana geldi");

      throw error; // Hata durumunu işleyin veya yeniden fırlatın.
    }
  }

  async SatinAlmaTalebindenTeklifeCevir(TalepGuid: string): Promise<any> {
    try {
      const response = await this.http
        .get(this.auth.ApiUrl + "talep/TaleptenTeklifeCevir?guid=" + TalepGuid)
        .toPromise();

      console.log("Alınan teklif :", response);
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async CevirlmisTeklifiMikroyaGonder(
    data: any,
    evrakSeri: string,
    evrakSira: string,
    kullaniciId: number
  ): Promise<any> {
    try {
      var response = await this.http
        .post(
          this.auth.ApiUrl +
          "teklif/TalepToTeklifFis?evrakSeri=" +
          evrakSeri +
          "&evrakSira=" +
          evrakSira +
          "&kullaniciId=" +
          kullaniciId,
          data
        )
        .toPromise();

      return response;
    } catch (error) {
      console.log("Error");
      console.log(error);
    }
  }

  async TaleptenSiparisOlustur(data: any, teklifGuid: string): Promise<any> {

    try {
      var response = await this.http.post(this.auth.ApiUrl + "siparis/VerilenTekliftenAlinanSipariseCevir?alinanTeklifGuid=" + teklifGuid, data).toPromise();
      return response;
    } catch (error) {
      console.log("Error");
      console.log(error);
    }
  }

  async TaleptenVerilenSipariseCevir(guid: string): Promise<any> {
    try {
      var response = await this.http.get(this.auth.ApiUrl + "talep/TaleptenVerilenSipariseCevir?talepGuid=" + guid).toPromise();
      console.log("Gelen response : ", response);

      return response;
    } catch (error) {
      console.log("Hata : ", error);
    }
  }

  async GetTeklifOnayList(
    basTar: any,
    bitTar: any,
    mikroAdi: string,
    onayVerilenleriGoster: boolean,
  ): Promise<EvrakOnayListModel> {
    var response = await this.http
      .get(
        this.auth.ApiUrl +
        "teklif/GetAlinanTeklifOnayList?basTar=" +
        basTar +
        "&bitTar=" +
        bitTar +
        "&mikroKullaniciAdi=" + mikroAdi
        + "&onayVerilenleriGoster=" + onayVerilenleriGoster
      )
      .toPromise();

    console.log("response");
    console.log(response);

    return response as EvrakOnayListModel;
  }

  TeklifOnay(teklifKodu, siraNo, mikroKullaniciAdi: string) {
    return new Promise((resolve, reject) => {
      const talepOnayModel = {
        EvrakNo: teklifKodu + "-" + siraNo,
        OnaylayanMikroKullaniciAdi: mikroKullaniciAdi,
      };

      var response = this.http
        .post(this.auth.ApiUrl + "teklif/TeklifOnay", talepOnayModel)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  tekliftenSipariseCevir(tkl_guid: string) {
    console.log("In the service ", tkl_guid);
    return new Promise((resolve, reject) => {
      var response = this.http
        .get(
          this.auth.ApiUrl +
          "siparis/AlinanTekliftenVerilenSipariseCevir?altkl_giud=" +
          tkl_guid
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  getVerilenTeklifOnaylist(basTar: string, bitTar: string) {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          this.auth.ApiUrl +
          "teklif/GetVerilenTeklifOnayList" +
          "?basTar=" +
          basTar +
          "&bitTar=" +
          bitTar
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  verilenTeklifOnayla(seriNo: string, siraNo: string) {
    const talepOnayModel = {
      EvrakNo: seriNo + "?" + siraNo,
      OnaylayanKullaniciId: localStorage.getItem("kullaniciId"),
    };

    return new Promise((resolve, reject) => {
      this.http
        .post(this.auth.ApiUrl + "teklif/VerilenTeklifOnay", talepOnayModel)
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  VerilenTeklifOnayKapat(seriNo: string, siraNo: string) {
    const talepOnayModel = {
      EvrakNo: seriNo + "?" + siraNo,
      OnaylayanKullaniciId: localStorage.getItem("kullaniciId"),
    };

    return new Promise((resolve, reject) => {
      this.http
        .post(
          this.auth.ApiUrl + "teklif/VerilenTeklifOnayKapat",
          talepOnayModel
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  public GetVerilenTeklif(tkl_guid: string) {

    return new Promise((resolve, reject) => {
      this.http
        .get(this.auth.ApiUrl + "teklif/GetVerilenTeklif?guid=" + tkl_guid)
        .subscribe(
          (response) => {
            console.log("resolve: ", response);

            resolve(response);
          },
          (error) => {
            console.log("reject: ", error);
            reject(error);
          }
        );
    });
  }

  public VerilenTekliftenAlinanSiparisAc(tkl_guid: string) {
    console.log("fonksiyonda kayitNo ", tkl_guid);
    return new Promise((resolve, reject) => {
      this.http
        .get(
          this.auth.ApiUrl +
          "siparis/VerilenTekliftenAlinanSipariseCevir?alinanTeklifGuid=" +
          tkl_guid
        )
        .subscribe(
          (response) => {
            resolve(response);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }

  GetDepolarArasiSiparisFisiHeader(basTar: string, bitTar: string) {

    return this.http.get(
      this.auth.ApiUrl +
      "DepolarArasiSiparis/GetAllHeaders?start=" +
      basTar +
      "&end=" +
      bitTar
    );
  }

  GetDepolarArasiSiparisFisi(guid: string) {
    return this.http.get(
      this.auth.ApiUrl + "DepolarArasiSiparis/GetSingleFis?guid=" + guid
    );
  }

  SaveDepolarArasiSiparisFisi(data: any) {
    return this.http.post(
      this.auth.ApiUrl + "DepolarArasiSiparis/SaveDepolarArasiSiparisFisi",
      data
    );
  }

  async AlinanTeklifTamOnay(teklifKodu: any, evrakSira: any, mikroKullaniciAdi: string): Promise<any> {

    try {
      const talepOnayModel = {
        EvrakNo: teklifKodu + "-" + evrakSira,
        OnaylayanMikroKullaniciAdi: mikroKullaniciAdi,
      };
      var response = await this.http.post(this.auth.ApiUrl + "teklif/AlinanTeklifTamOnay", talepOnayModel).toPromise();

    } catch (error) {
      console.log("Alinan teklif tam onayda hata oluştu: ", error);
    }
  }

  async AlinanTeklifKapat(evrakSeri: any, evrakSira: any, mikroKulaniciAdi: string): Promise<any> {

    try {
      const talepOnayModel = {
        EvrakNo: evrakSeri + "-" + evrakSira,
        OnaylayanKullaniciId: mikroKulaniciAdi,
      };
      var response = await this.http.post(this.auth.ApiUrl + "teklif/AlinanTeklifKapat", talepOnayModel).toPromise();

    } catch (error) {
      console.log("Alinan teklif tam onayda hata oluştu: ", error);
    }
  }

  async verilenTeklifTamOnayVer(teklifKodu, teklifSiraNo): Promise<any> {

    try {
      const talepOnayModel = {
        EvrakNo: teklifKodu + "?" + teklifSiraNo,
        OnaylayanKullaniciId: localStorage.getItem("kullaniciId"),
      };
      var response = await this.http.post(this.auth.ApiUrl + "teklif/VerilenTeklifTamOnay", talepOnayModel).toPromise();

    } catch (error) {
      console.log("Alinan teklif tam onayda hata oluştu: ", error);
    }
  }

  public async apiVersiyonuAl(): Promise<any> {
    try {
      var response = await this.http.get(this.auth.ApiUrl + "lookup/GetAPISurum").toPromise();

      return response;
    }
    catch (error) {

      console.log("API versiyonu alınırken hata : ", error);

      return 0;

    }

  }

  public async GetEvraklar(): Promise<any> {
    try {
      var response = await this.http.get(this.auth.ApiUrl + "lookup/GetEvraklar").toPromise();

      return response;

    } catch (error) {

      console.log("Evrak bilgilerini alırken hata : ", error);


    }

  }


  public async GetStokTanitimKarti(stokKodu: string): Promise<StokTanitimKartiModel> {
    try {

      console.log("Stok tanitim karti aliniyor");
      var response = await this.http.get<StokTanitimKartiModel>(this.auth.ApiUrl + "stok/GetStokTanitimKarti?stokKodu=" + stokKodu).toPromise();
      console.log("Stok tanitim karti alindi : ", response);

      return response;

    } catch (exception) {
      console.log("Tanitim karti alirken hata :", exception);
    }
  }

  public StokTanitimKartiKaydet(tanitimKarti: StokTanitimKartiModel) {
    try {
      return this.http.patch(this.auth.ApiUrl + "stok/StokTanitimKartiKaydet", tanitimKarti);

    } catch (exception) {
      console.log("Tanıtım kartını kaydederken hata : ", exception);
    }
  }

  async getAnaGruplar() {

    return await this.http.get<KeyValueModel[]>(this.auth.ApiUrl + "lookup/GetStokAnaGruplari").toPromise();
  }

  async getAltGruplar(anaGrupKodu: string) {
    return await this.http.get<KeyValueModel[]>(this.auth.ApiUrl + "lookup/GetStokAltGruplari?anaGrupKodu=" + anaGrupKodu).toPromise();
  }

  async getKategoriler() {
    return await this.http.get<KeyValueModel[]>(this.auth.ApiUrl + "lookup/GetStokKategorileri").toPromise();
  }

  async getFirmalar(filtre: string) {
    return await this.http.get<KeyValueModel[]>(this.auth.ApiUrl + "lookup/GetFirmalar?filtre=" + filtre).toPromise();
  }

  async getSorumlulukMerkezleri(filtre: string) {
    return await this.http.get<KeyValueModel[]>(this.auth.ApiUrl + "lookup/GetSorumlulukMerkezleri?filtre=" + filtre).toPromise();
  }

  async getProjeler(filtre: string) {
    return await this.http.get<any[]>(this.auth.ApiUrl + "lookup/GetProjeler?filtre=" + filtre).toPromise();
  }

  async kasalardaAra(filtre: string) {
    return await this.http.get<KeyValueModel[]>(this.auth.ApiUrl + "lookup/KasalardaAra?filtre=" + filtre).toPromise();
  }

  async getSubeler(firmaNo: number, filtre: string) {
    return await this.http.get<KeyValueModel[]>(this.auth.ApiUrl + "lookup/GetSubeler?firmaNo=" + firmaNo + "&filtre=" + filtre).toPromise();
  }

  async getSubeNo(subeAdi: number) {
    return await this.http.get<number>(this.auth.ApiUrl + "lookup/GetSubeNo?subeAdi=" + subeAdi).toPromise();
  }

  async depolardaAra(filtre: string) {
    return await this.http.get<KeyValueModel[]>(this.auth.ApiUrl + "lookup/DepolardaAra?filtre=" + filtre).toPromise();
  }

  public async getSarfCikisiDepoFisiListe(baslangicTarihi: string, bitisTarihi: string) {
    return await this.http.get<SarfCikisiDepoFisiModel[]>(this.auth.ApiUrl + "sarfCikisi/GetList?baslangicTarihi=" + baslangicTarihi + "&bitisTarihi=" + bitisTarihi).toPromise();
  }

  public async saveSarfCikisiDepoFisiListe(model: SarfCikisiDepoFisiModel) {
    return await this.http.post<SarfCikisiDepoFisiModel[]>(this.auth.ApiUrl + "sarfCikisi/yeniFisKaydet", model).toPromise();
  }

  public async sarfFisiKilitliMi(evrakSeri: string, evrakSira: string) {
    return await this.http.get<boolean>(this.auth.ApiUrl + "sarfCikisi/EvrakKilitliMi?evrakSeri=" + evrakSeri + "&evrakSira=" + evrakSira).toPromise();
  }

  public async getSingleSarfFisi(evrakSeri: string, evrakSira: number) {

    return await this.http.get<SarfCikisiDepoFisiModel>(this.auth.ApiUrl + "sarfCikisi/GetSingleSarfFisi?evrakSeri=" + evrakSeri + "&evrakSira=" + evrakSira).toPromise();
  }

  public async getStokSonSatisFiyati(stokKodu: string) {
    return await this.http.get<number>(this.auth.ApiUrl + "lookup/getStokSonSatisFiyati?stokKodu=" + stokKodu).toPromise();
  }

  public getMaxSarfFisiSira(evrakSeri: string) {
    return this.http.get(this.auth.ApiUrl + "sarfCikisi/GetMaxSiraNo?evrakSeri=" + evrakSeri);
  }

  public GetDepoAdi(DepoNo: number) {
    return this.http.get(this.auth.ApiUrl + + DepoNo).toPromise();
  }

  public async GetMikroKullanicilari(filtre: string) {

    console.log("API get kullanicilar filtre : ", filtre);
    return await this.http.get<KeyValueModel[]>(this.auth.ApiUrl + "lookup/GetMikroKullanicilari?filtre=" + filtre).toPromise();
  }


  public async GetPlasiyerListesi(filtre: string) {
    return await this.http.get<KeyValueModel[]>(this.auth.ApiUrl + "lookup/GetSorumlular?filtre=" + filtre).toPromise();
  }

  public async EvrakOnayiPdfeDonustur(evrakNo: string) {

    var data = await this.http.get<string>(this.auth.ApiUrl + "talep/GetTalepOnayDokumani?evrakNo=" + evrakNo).toPromise();

    return data;

    // return data;
  }


  public GetKayitliKullanici(): KisiModel {
    var kullanici: KisiModel = JSON.parse(localStorage.getItem("kullaniciBilgileri"));

    return kullanici;
  }
}
