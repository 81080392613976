import { Injectable, isDevMode } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { CurrentUser } from "./current-user";
import { environment } from "environments/environment";
import { LoginComponent } from "./login/login.component";
import { TalepOnayComponent } from "./talep/talep-onay/talep-onay.component";
import { MikroService } from "./mikro.service";
import { KeyValueModel } from "./models/key_value_model";
import { KisiModel } from "./components/admin_panel/kisi_model.component";

@Injectable()
export class AuthService {
  currentUser: CurrentUser;
  ApiUrl: string;
  private _DepoNo: number = undefined;
  private _DepoAdi: string = undefined;

  private _SrmMerkezi: string = undefined;
  private _SrmMerkeziAdi: string = undefined;

  private _ProjeKodu: string = undefined;
  private _ProjeAdi: string = undefined;

  private _FirmaNo: number = undefined;
  private _FirmAdi: string = undefined;

  private _SubeNo: number = undefined;
  private _SubeAdi: string = undefined;

  private _EvrakSeri: string = undefined;

  private _mikroKullaniciNo = undefined;
  private _mikroKullaniciAdi = undefined;

  private _kasaKodu = undefined;
  private _kasaAdi = undefined;

  private _apiVersion = 0;

  constructor(private http: HttpClient) {


  }


  signout() {
    this.currentUser = null;
  }

  signin(signinFormValue): Observable<CurrentUser> {
    return this.http
      .post<CurrentUser>(environment.authUrl + "authenticate", {
        UserName: signinFormValue.email,
        Password: signinFormValue.password,
        LoginType: 1,
      })
      .pipe(
        map((user) => {
          if (user && user.accessToken) {
            this.currentUser = user;
          }

          this.currentUser.attributes.forEach((element) => {
            if (element.attributeKey == "ApiUrl") {
              // if (isDevMode) {
              //   this.ApiUrl = "http://localhost:6800/api/";
              // } else 
              {
                console.log("api url : ", element.attributeValue);
                this.ApiUrl = element.attributeValue;
              }
            }
            // else if (element.attributeKey == "DepoNo") {
            //   this.DepoNo = Number.parseInt(element.attributeValue);
            // } else if (element.attributeKey == "SrmMerkezi") {
            //   this.SrmMerkezi = element.attributeValue;
            // } else if (element.attributeKey == "ProjeKodu") {
            //   this.ProjeKodu = element.attributeValue;
            // } else if (element.attributeKey == "FirmaNo") {
            //   this.FirmaNo = Number.parseInt(element.attributeValue);
            // } else if (element.attributeKey == "SubeNo") {
            //   this.SubeNo = Number.parseInt(element.attributeValue);
            // } else if (element.attributeKey == "EvrakSeri") {
            //   this.EvrakSeri = element.attributeValue;
            // }
          });


          // console.log("Kayittan cekilen kullanici bilgielri ", kullaniciBilgileri);


          // this.ProjeKodu = kullaniciBilgileri["projeKodu"];

          // this.EvrakSeri = kullaniciBilgileri["evrakSeri"];



          return <CurrentUser>user;
        })
      );
  }

  refreshToken(): Observable<CurrentUser> {
    const atoken = this.currentUser.accessToken;
    const rtoken = this.currentUser.refreshToken;
    return this.http
      .post<CurrentUser>(environment.authUrl + "refresh", {
        token: atoken,
        refreshToken: rtoken,
      })
      .pipe(
        map((user) => {
          if (user && user.accessToken) {
            this.currentUser = user;
          }
          return <CurrentUser>user;
        }),
        catchError(this.handleError)
      );
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      console.error("An error occurred:", error.error.message);
    } else {
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
    }
    return throwError("Something bad happened; please try again later.");
  }

  getAuthToken(): string {
    if (this.currentUser != null) {
      return this.currentUser.accessToken;
    } else return "";
  }

  getKayitliKullaniciBilgileri(): any {
    return localStorage.getItem("kullaniciBilgileri") !== null ? JSON.parse(localStorage.getItem("kullaniciBilgileri")) : null;
  }

  public get SubeNo(): number {

    if (this._SubeNo == undefined || this._SubeNo == null) {

      this.subeNoCek().then((value) => {

        console.log("Sube no :", this._SubeNo);

        return this._SubeNo;
      });


    }
    else {

      console.log("Sube no :", this._SubeNo);

      return this._SubeNo;

    }


  }



  public get SrmMerkezi(): string {

    if (this._SrmMerkezi == undefined || this._SrmMerkezi == null) {

      this.srmMerkeziniCek().then((value) => {

        console.log("Srm mrk :", this._SrmMerkezi);

        return this._SrmMerkezi;

      });

    }
    else {
      console.log("Srm mrk :", this._SrmMerkezi);
      return this._SrmMerkezi;
    }


  }

  public get DepoNo(): number {

    // kullaniciBilgileri["depoNo"] alanına depoNo yerine depoAdi atanıyor.

    if (this._DepoNo === undefined || this._DepoNo === null) {


      this.depoNoCek().then((value) => {

        console.log("Depo no :", this._DepoNo);

        return this._DepoNo;
      });

    }
    else {

      console.log("Depo no :", this._DepoNo);

      return this._DepoNo;

    }
  }

  public get ProjeKodu(): string {



    if (this._ProjeKodu === undefined || this._ProjeKodu === null) {

      this.projeKoduCek().then((_) => {
        console.log("Proje kodu : ", this._ProjeKodu);


        return this._ProjeKodu;
      });


    }
    else {
      console.log("Proje kodu : ", this._ProjeKodu);
      return this._ProjeKodu;
    }

  }

  public get FirmaNo(): number {

    if (this._FirmaNo === undefined || this._FirmaNo == null) {

      this.firmaNoCek().then((value) => {

        console.log("Firma no :", this._FirmaNo);
        return this._FirmaNo;
      });

    }
    console.log("Frima no :", this._FirmaNo);

    return this._FirmaNo;
  }

  public get EvrakSeri(): string {

    if (this._EvrakSeri == undefined || this._EvrakSeri == null) {
      this._EvrakSeri = "";
    }
    console.log("Evrak seri", this._mikroKullaniciNo);

    return this._EvrakSeri;
  }

  public get mikroKullaniciNo() {



    if (this._mikroKullaniciNo === undefined || this._mikroKullaniciNo === null) {

      this.mikroKullaniciNoCek().then((_) => {

        console.log("Mikro kullanici no ", this._mikroKullaniciNo);

        return this._mikroKullaniciNo;
      });

    }
    else {
      console.log("Mikro kullanici no ", this._mikroKullaniciNo);

      return this._mikroKullaniciNo;

    }

  }

  public get kasaKodu() {


    if (this._kasaKodu === undefined || this._kasaKodu === null) {

      this.kasaKodunuCek().then((_) => {

        console.log("Kasa kodu : ", this._kasaKodu);
        return this._kasaKodu;
      });

    }
    else {

      console.log("Kasa kodu : ", this._kasaKodu);

      return this._kasaKodu;

    }

  }

  public async tumVerileriAl() {

    this.bellektenKullaniciVerileriniOku();

    await this.subeNoCek();
    await this.srmMerkeziniCek();
    await this.depoNoCek();
    await this.projeKoduCek();
    await this.firmaNoCek();
    await this.mikroKullaniciNoCek();
    await this.kasaKodunuCek();

  }

  private bellektenKullaniciVerileriniOku() {
    var kullaniciBilgileri = JSON.parse(localStorage.getItem("kullaniciBilgileri"));

    this._kasaAdi = kullaniciBilgileri["kasaKodu"];
    this._mikroKullaniciAdi = kullaniciBilgileri["mikroKullaniciNo"];
    this._EvrakSeri = kullaniciBilgileri["evrakSeri"];
    this._FirmAdi = kullaniciBilgileri["firmaNo"];
    this._ProjeAdi = kullaniciBilgileri["projeKodu"];
    this._DepoAdi = kullaniciBilgileri["depoNo"];
    this._SrmMerkeziAdi = kullaniciBilgileri["srmMrkKodu"];
    this._SubeAdi = kullaniciBilgileri["subeNo"];


  }

  private async subeNoCek() {

    if (this._SubeAdi === null || this._SubeAdi === undefined)
      return;
    var subeler = await this.http.get<KeyValueModel[]>(this.ApiUrl + "lookup/GetSubeler?filtre=" + this._SubeAdi).toPromise();

    this._SubeNo = parseInt(subeler[0].key);
  }

  private async srmMerkeziniCek() {

    if (this._SrmMerkeziAdi === null || this._SrmMerkeziAdi === undefined)
      return;
    var srmMerkezi = await this.http.get<KeyValueModel[]>(this.ApiUrl + "lookup/GetSorumlulukMerkezleri?filtre=" + this._SrmMerkeziAdi).toPromise();

    this._SrmMerkezi = srmMerkezi[0].key;

  }

  private async depoNoCek() {


    if (this._DepoAdi === null || this._DepoAdi === undefined)
      return;
    var depoNo = await this.http.get<KeyValueModel[]>(this.ApiUrl + "lookup/DepolardaAra?filtre=" + this._DepoAdi).toPromise();

    console.log("Gelen depo no: ", depoNo);

    this._DepoNo = parseInt(depoNo[0].key);

  }

  private async projeKoduCek() {


    if (this._ProjeAdi === null || this._ProjeAdi === undefined)
      return;

    var projeKodu = await this.http.get<any[]>(this.ApiUrl + "lookup/GetProjeler?filtre=" + this._ProjeAdi).toPromise();
    this._ProjeKodu = projeKodu[0].kodu;


  }

  private async firmaNoCek() {


    if (this._FirmAdi === null || this._FirmAdi === undefined)
      return;

    var firmaNo = await this.http.get<KeyValueModel[]>(this.ApiUrl + "lookup/GetFirmalar?filtre=" + this._FirmAdi).toPromise();

    this._FirmaNo = parseInt(firmaNo[0].key);

  }

  private async mikroKullaniciNoCek() {

    if (this._mikroKullaniciAdi === null || this._mikroKullaniciAdi === undefined)
      return;

    var kullaniciNo = await this.http.get<KeyValueModel[]>(this.ApiUrl + "lookup/GetMikroKullanicilari?filtre=" + this._mikroKullaniciAdi).toPromise();
    this._mikroKullaniciNo = kullaniciNo[0].key;
  }


  private async kasaKodunuCek() {

    if (this._kasaAdi === null || this._kasaAdi === undefined)
      return;
    var kasaKodu = await this.http.get<KeyValueModel[]>(this.ApiUrl + "lookup/KasalardaAra?filtre=" + this._kasaAdi).toPromise();

    this._kasaKodu = kasaKodu[0].key;
  }

  public get apiVersion() {
    return this._apiVersion;
  }

  public setApiVersion(apiVersion: number) {
    this._apiVersion = apiVersion;
  }

  public kayitliKisiModeliCek(): KisiModel {
    var kullanici: KisiModel = JSON.parse(localStorage.getItem("kullaniciBilgileri"));

    return kullanici;
  }

  public kacinciOnaySatinalmaTalebi() {
    var kisiModel: KisiModel = this.kayitliKisiModeliCek();

    if (kisiModel.satinAlmaTalebiOnay1 === true)
      return 1;
    if (kisiModel.satinAlmaTalebiOnay2 === true)
      return 2;
    if (kisiModel.satinAlmaTalebiOnay3 === true)
      return 3;
  }


  public kacinciOnayAlinanTeklif() {
    var kisiModel: KisiModel = this.kayitliKisiModeliCek();

    if (kisiModel.alinanTeklifOnay1 === true)
      return 1;
    if (kisiModel.alinanTeklifOnay2 === true)
      return 2;
    if (kisiModel.alinanTeklifOnay3 === true)
      return 3;
  }
}

